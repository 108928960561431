import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { Mesh } from 'three'
import gsap from 'gsap';



// console.log("GSAP", gsap)

//GLOBAL VARIABLES
let mouseX = 0, mouseY = 0;

let windowHalfX = window.innerWidth /2;
let windowHalfY = window.innerHeight/2;

var isMobile = false; //initiate as false
// device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    isMobile = true;
}


let audioAllowed = false;
// console.log("MUSIC PLAYING", music.playing())

const loadingScreen = document.querySelector('.loading-screen');
const loadingSVG = document.querySelector('.loading-svg');
loadingSVG.src = 'RecoLoading.svg'
if(!isMobile) { 

    const loadingManager = new THREE.LoadingManager(
        () =>{
            console.log("isLoaded")
            window.setTimeout(() => {
                gsap.to('.loading-content', {autoAlpha: 0, duration: 1});
                gsap.to('.loading-screen', {autoAlpha: 0, duration: 2});
            }, 1000)
        
        },
        (itemUrl, itemsLoaded, itemsTotal) => {
            console.log("isProgress")
            const progressRatio = itemsLoaded / itemsTotal;
            console.log(progressRatio)
        }
    )



    /**
     * Base
     */
    // Canvas
    const canvasloader = document.querySelector('canvas.webgl')

    // Scene
    const scene = new THREE.Scene()

    const cubeTextureLoader = new THREE.CubeTextureLoader();
    const environmentMap = cubeTextureLoader.load([
        'Standard-Cube-Map/px.png',
        'Standard-Cube-Map/nx.png',
        'Standard-Cube-Map/py.png',
        'Standard-Cube-Map/ny.png',
        'Standard-Cube-Map/pz.png',
        'Standard-Cube-Map/nz.png'
    ])
    environmentMap.encoding = THREE.GammaEncoding;
    environmentMap.gammaOutput = true;
    environmentMap.gammaFactor = 2.2;
    scene.environment = environmentMap;


    // let totalWidth = document.querySelector('#Layer_1').style.width;
    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }



    window.addEventListener('resize', () =>
    {
            // Update sizes
            sizes.width = window.innerWidth
            sizes.height = window.innerHeight

            // Update camera
            camera.aspect = sizes.width / sizes.height
            // camera.position.z = -sizes.width / 1000;
            if(window.innerWidth < 450){
                camera.position.z = 2.3;
                camera.position.y = 0.4;
            } else {
                camera.position.z = 1.8;
                camera.position.y = 0.3;
            }

            //Check for controls
            if(window.innerWidth < 1100){
                document.querySelector('.controls-section').style.display = 'none';
            } else {
                document.querySelector('.controls-section').style.display = 'block';
            }

            

            camera.updateProjectionMatrix()

            // Update renderer
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

           // document.querySelector('.loadingWrapper').style.height = document.querySelector('.loadingWrapper #Layer_1').clientHeight + "px";
    })


    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    // camera.position.x = 1.25 //1 is origniaal
    // camera.position.y = 0.3 //0.5 is orignal
    // camera.position.z = 1.25 //1 is orignal
    scene.add(camera)

    camera.position.x = 0;
    // camera.position.z = 1.8;
    if(window.innerWidth < 450){
        camera.position.z = 2.3;
        camera.position.y = 0.4;
    } else {
        camera.position.z = 1.8;
        camera.position.y = 0.3;
    }
    camera.far = 30;

    if(window.innerWidth < 1100){
        document.querySelector('.controls-section').style.display = 'none';
    } 


    const directionalLight = new THREE.DirectionalLight(0xFFEED5, 0.9)
    directionalLight.position.set(3.0, 1.2, 0.2);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.set(2048, 2048);
    scene.add(directionalLight);
    // const helper = new THREE.DirectionalLightHelper( directionalLight, 2.5 );
    // scene.add( helper );
    directionalLight.shadow.camera.top = 3;
    directionalLight.shadow.camera.right = 3;
    directionalLight.shadow.camera.left = -3;
    directionalLight.shadow.camera.bottom = -3;
    directionalLight.shadow.radius = 2;

    const hemLight = new THREE.HemisphereLight(0xFFEED5, 0xffffff, 0.8);
    scene.add(hemLight)


    //MATERIALS
    const updateAllMaterials = () => {
        scene.traverse((child) => {
            // console.log(child.name)
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial){
            
                    child.material.envMapIntensity =15;
                    // child.material.envMap: environmentMap;
                    child.material.update = true;
        
                    child.castShadow = true;
                    child.receiveShadow = true;
                
            }

        })
    }

    const waterMaterial = new THREE.MeshPhysicalMaterial({
        color: "#064273",
        transparent: true,
        opacity: 0.9,
        roughness: 0.6,
        side: THREE.DoubleSide,
        ior: 1.1
    })



    const couchMaterial = new THREE.MeshStandardMaterial({
        color: "#ED4A8D",
        roughness: 0.553

    })

    // Controls
    // const controls = new OrbitControls(camera, canvas)
    // controls.enableDamping = false;

    //IMPORTED SCENE
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');
    const gltfLoader = new GLTFLoader(loadingManager);
    gltfLoader.setDRACOLoader(dracoLoader);



    const sceneGroup = new THREE.Group();
    const recoGroup = new THREE.Group();


    gltfLoader.load(
        'RECO_Island2.glb',
        (gltf) => {
            // console.log(gltf.scene);

            //USE THIS LOGIC FOR BUILDING
            const couch = gltf.scene.children[0].children.find((child) => child.name == 'Couch');
            couch.material = couchMaterial;

            const building = gltf.scene.children[0].children.find((child) => child.name == 'Building');
            console.log("BUILDING", building)
            const buildingBody = building.children[0];
            buildingBody.material = new THREE.MeshPhysicalMaterial({
                color: "#E7DCA3",
                roughness: 1
            })
            const buildingTrim = building.children[1];
            buildingTrim.material = new THREE.MeshPhysicalMaterial({
                color: "#E7E1DA",
                roughness: 1
            })

            const buildingRoof = building.children[2];
            buildingRoof.material = new THREE.MeshStandardMaterial({
                color: "#864636",
                roughness: 1
            })

            const buildingWindows = building.children[3];
            buildingWindows.material = new THREE.MeshStandardMaterial({
                color: "#778899",
                metalness: 0.9,
                roughness: 0.2
            })

            // console.log("Landscape", gltf.scene.children[0].children.find((child) => child.name == 'Landscape'))



            //LANDSCAPE
            const sand = gltf.scene.children[0].children.find((child) => child.name == 'Landscape_2');
            sand.material = new THREE.MeshPhysicalMaterial({
                color: "#E7C597",
                roughness: 0.858,
                metalness: 0
            })

            const grass = gltf.scene.children[0].children.find((child) => child.name == 'Landscape_1');
            grass.material = new THREE.MeshPhysicalMaterial({
                color: "#4BA137",
                roughness: 0.858,
                metalness: 0.7
            })

            const snow = gltf.scene.children[0].children.find((child) => child.name == 'Landscape_3');
            snow.material = new THREE.MeshStandardMaterial({
                color: "#e7e7e7",
                roughness: 0.858,
                metalness: 0
            })

            const rock = gltf.scene.children[0].children.find((child) => child.name == 'Landscape_4');
            rock.material = new THREE.MeshPhysicalMaterial({
                color: "#4f4f59",
                roughness: 0.7,
                metalness: 0.9
            })

            const debris = gltf.scene.children[0].children.filter((child) => child.name.includes("Debris"));
            debris.forEach((obj) => {
                obj.material = new THREE.MeshPhysicalMaterial({
                    color: '#270F04',
                    roughness: 0.5,
                    metalness: 0
                })
            })



            
            const recosign = gltf.scene.children[1];

            const recoOuter = recosign.children[0];
            recoOuter.material = new THREE.MeshBasicMaterial({
                color: "#172D4C",
            })

            const recoInner = recosign.children[1];
            recoInner.material = new THREE.MeshBasicMaterial({
                color: "#FFFFFF",
    
            })

            const recoBase = recosign.children[2];
            recoBase.material = new THREE.MeshBasicMaterial({
                color: "#ED2A80"
                // metalness: 0.8,
                // roughness: 0.553
            })
            recoGroup.add(recosign)

            camera.lookAt(recoGroup.position.x, recoGroup.position.y, recoGroup.position.z)
            camera.updateProjectionMatrix();
            // recoGroup.rotation.y = Math.PI * 1/4;
            recoGroup.scale.set(0.2,0.2,0.2)
            recoGroup.position.y = -10;
            scene.add(recoGroup)


            const water = gltf.scene.children[0].children.find((child) => child.name == 'Water');
            water.material = waterMaterial;
    

            sceneGroup.add(gltf.scene.children[0]);
            sceneGroup.rotation.y = Math.PI * 1/4;
            sceneGroup.position.z = -40;
            sceneGroup.scale.set(0.1,0.1,0.1)
            // sceneGroup.position.y = 50;
            // console.log("SceneGroup: ", sceneGroup)
        
            // scene.add(gltf.scene);
            scene.add(sceneGroup)

            updateAllMaterials();

            isLoaded();
        }
    )


    const isLoaded = () => {
    
        document.querySelector('.loadingWrapper').style.height = document.querySelector('.loadingWrapper #Layer_1').clientHeight + "px";
        window.setTimeout(() => {
            gsap.to(sceneGroup.position, {z: 0, duration: 2, ease: "Power3.easeOut"});
            // gsap.to(recoGroup.position, {y: 0, duration: 1.5, ease: "ease"})
            gsap.to(recoGroup.position, {y: 0, duration: 2.5, ease: "elastic.out(0.5, 0.2)", delay: 2})
        // insertCoinDisplay();
        }, 900);
    }


    let rectOutline;
    let rectPunched;
    let finishedLoading = false;

    const insertCoinDisplay = () => {

        rectOutline = document.querySelector('#fade').querySelector('rect');
        rectPunched = document.querySelector('#fade2').querySelector('rect');
        let twid = document.querySelector('.loadingWrapper').offsetWidth;

        // minHeightLoadingWrapper = document.querySelector('.loadingWrapper').style.minHeight;
        // console.log(minHeightLoadingWrapper, "MHLW")
        // document.querySelector('.loadingWrapper').style.height = document.querySelector('.loadingWrapper #Layer_1').height + "px";
        let loadingBarTimeline = gsap.timeline({onComplete: loadInsertCoinSVG});
        // loadingBarTimeline.add("start", 0)
        console.log("timeline", loadingBarTimeline)
        //LOADING BAR ANIMATION
        loadingBarTimeline.to(rectOutline, {x:rectOutline.getAttribute('width'), duration: 10}, 0);
        loadingBarTimeline.to(rectPunched, {width: rectOutline.getAttribute('width'), duration: 10}, 0);
        

        let imageWrapper = document.querySelector('.imageWrapper')
        let iw = imageWrapper.querySelector('svg')

        gsap.to(iw, {autoAlpha: 1, translateY: 0, duration: 1.3, delay: 4.5});
        // iw.style.opacity = "1"

        let recosite = document.querySelector('.reco-redirect');
        // gsap.to(recosite, {autoAlpha: 1, translateY: 0, duration: 1.3, delay: 2})

    }


    let outAnimationClass;

    const loadInsertCoinSVG = () => {
        // alert("DONE")
        let loadingExperienceRemove = gsap.timeline();
        let insertCoinAnimation = gsap.timeline();
        insertCoinAnimation.delay(1.9)
        insertCoinAnimation.repeat(-1);

        loadingExperienceRemove.to(rectOutline, {autoAlpha: 0, duration: 1.3}, 0)
        loadingExperienceRemove.to(rectPunched, {autoAlpha: 0, duration: 1.3}, 0)
        loadingExperienceRemove.to('#insert-coin-solid', {autoAlpha: 1, duration: 1.3}, 0)
        loadingExperienceRemove.to('#insert-coin', {autoAlpha: 1, duration: 0, delay: 1.3}, 0);
        //Bring in the insert coins
        window.setTimeout(() => {
            // document.querySelector('#insert-coin-solid').classList.add("insertCoinClickable")
            document.querySelector('.loadingWrapper').classList.add("insertCoinClickable")
            outAnimationClass = document.querySelector('.insertCoinClickable')
            
            outAnimationClass.onclick = function(){
               outanim();
            }
            console.log(outAnimationClass)
                
        }, 1300);

        insertCoinAnimation.to('#insert-coin-solid', {autoAlpha: 0, duration: 1.3});
        insertCoinAnimation.to('#insert-coin-solid', {autoAlpha: 1, duration: 1.3});
    
    }


    window.outanim = () => {
        let background = document.querySelector(".loading-screen");
        background.style.backgroundColor = "rgba(0,0,0,0)";
        
        let imageWrapper = document.querySelector('.imageWrapper');
        gsap.to(imageWrapper, {autoAlpha: 0, duration: 1})
    
            // gsap.to(sceneGroup.rotation, {y: -0.4, duration: 2})
            // gsap.to(camera.position, {x: 0.15, y:0.05, z:0.2, duration: 2, ease: "power3.out"});
            gsap.to(camera.position, {x: 0.2, y:0.0, z:0.1, duration: 2, ease: "slow(0.7,0.7,false)"});
            camera.updateProjectionMatrix();
            
        window.setTimeout(() => {
            gsap.to('.loading-screen', { autoAlpha: 1, duration: 1, ease: "power4.out"})
        }, 200)
    
        window.setTimeout(() => {
            window.cancelAnimationFrame(reqAnim)
            startTrigger();
        }, 1000)
    }

    let mixer = null;
    let mixer1 = null;
    let mixer2 = null;
    let bird = new THREE.Group();

    gltfLoader.load(
        'Bird.glb',
        (gltf) =>{
            

            gltf.scene.scale.set(0.01, 0.01, 0.01);
            gltf.scene.position.y = 1;
            gltf.scene.position.x = -5;
            gltf.scene.position.z = -0.5;

            mixer = new THREE.AnimationMixer(gltf.scene);
            const action = mixer.clipAction(gltf.animations[0])
            const action2 = mixer.clipAction(gltf.animations[1])
            window.setTimeout(() => {
                action.play();
                action2.play();
            }, 500)
            action.timeScale =2;
            action2.timeScale=2;
            // console.log("Animation", action)

            gltf.scene.rotation.y = Math.PI/2;
            

            bird.add(gltf.scene);

            scene.add(bird)

        }
    )

    gltfLoader.load(
        'Bird.glb',
        (gltf) =>{
            // console.log(gltf.animations[1], "BIR111D")


            gltf.scene.scale.set(0.01, 0.01, 0.01);
            gltf.scene.position.y = 0.7;
            gltf.scene.position.x = -5.3;
            gltf.scene.position.z = -0.5;

            mixer1 = new THREE.AnimationMixer(gltf.scene);
            const action = mixer1.clipAction(gltf.animations[0])
            const action2 = mixer1.clipAction(gltf.animations[1])
            action.play();
            action2.play();
            action.timeScale =2;
            action2.timeScale=2;
            // console.log("Animation", action)

            gltf.scene.rotation.y = Math.PI/2

            bird.add(gltf.scene);

            

        }
    )

    //BIRD3
    gltfLoader.load(
        'Bird.glb',
        (gltf) =>{
            // console.log(gltf.animations[1], "BIR111D")


            gltf.scene.scale.set(0.006, 0.006, 0.006);
            gltf.scene.position.y = 0.5;
            gltf.scene.position.x = -9.3;
            gltf.scene.position.z = -0.9;

            mixer2 = new THREE.AnimationMixer(gltf.scene);
            const action = mixer2.clipAction(gltf.animations[0])
            const action2 = mixer2.clipAction(gltf.animations[1])
            window.setTimeout(() => {
                action.play();
                action2.play();
            }, 100)
            action.timeScale =2;
            action2.timeScale=2;
            // console.log("Animation", action)

            gltf.scene.rotation.y = Math.PI/2

            bird.add(gltf.scene);

            

        }
    )

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvasloader,
        antialias: true,
        transparent: true,
        alpha: true
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    // renderer.outputEncoding = THREE.sRGBEncoding;
    // renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;
    // renderer.physicallyCorrectLights = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    // renderer.gammaFactor = 2.2;

    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let lastElapsedTime = 0
    // let offset = 0.5
    let reqAnim;
    let shouldRotate = true;


    const rotateScene = (time, sr) => {
        if (sr){
            sceneGroup.rotation.y = -time*0.6;
        }
    
    }

    const tick = () =>
    {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - lastElapsedTime
        lastElapsedTime = elapsedTime

        // sceneGroup.rotation.y = -elapsedTime*0.6;

        rotateScene(elapsedTime, shouldRotate);
        
        // console.log(sceneGroup.rotation.y);
        sceneGroup.position.y = sceneGroup.position.y * Math.sin(elapsedTime)*0.05 - 0.1;
        //Move the bird

        bird.children.forEach(b => {
            if(b.position.x < 10) {
                b.position.x += 0.005;
                // b.position.y = offset + Math.sin(elapsedTime*2)*0.01;
            } else {
                b.position.x = -5;
                // offset = Math.random()/3 + 0.5;
            }
        })


        recoGroup.rotation.z = -Math.sin(elapsedTime *2)*0.1;

        renderer.shadowMap.needsUpdate = true;


        if(mixer!== null){
            mixer.update(deltaTime);
            // console.log("mixy")
        }
        if(mixer1!== null){
            mixer1.update(deltaTime);
            // console.log("mixy")
        }
        if(mixer2!== null){
            mixer2.update(deltaTime);
            // console.log("mixy")
        }

        renderer.render(scene, camera)

        reqAnim = window.requestAnimationFrame(tick);
    }


    //Each button
    let wKey = document.querySelector('#Rectangle_1')
    let aKey = document.querySelector('#Rectangle_3')
    let sKey = document.querySelector('#Rectangle_2')
    let dKey = document.querySelector('#Rectangle_4')
    let runKey = document.querySelector("#Rectangle_6")
    let lookKey = document.querySelector("#Path_9")
    let jumpKey = document.querySelector("#Rectangle_7")
    let runKeyPressed = false;

    //Listeners
    document.addEventListener('keydown', (event) => {
        if(event.key === 'w'){
            wKey.style.fill = "rgba(255,255,255,0.45)"
        } else if (event.key === 'a') {
            aKey.style.fill = "rgba(255,255,255,0.45)"
        } else if (event.key === 's') {
            sKey.style.fill = "rgba(255,255,255,0.45)"
        } else if (event.key === 'd') {
            dKey.style.fill = "rgba(255,255,255,0.45)"
        } else if (event.shiftKey) {
            runKey.style.fill = "rgba(255,255,255,0.45)"
            runKeyPressed = true;
        } else if (event.code === "Space") {
            jumpKey.style.fill = "rgba(255,255,255,0.45)"
        }
    
            
    })

    document.addEventListener('keyup', (event) => {
        if(event.key === 'w'){
            wKey.style.fill = "rgba(255,255,255,0.15)"
        } else if (event.key === 'a') {
            aKey.style.fill = "rgba(255,255,255,0.15)"
        } else if (event.key === 's') {
            sKey.style.fill = "rgba(255,255,255,0.15)"
        } else if (event.key === 'd') {
            dKey.style.fill = "rgba(255,255,255,0.15)"
        } else if (event.code === "Space") {
            jumpKey.style.fill = "rgba(255,255,255,0.15)"
        } else if(runKeyPressed) {
            runKeyPressed = false;
            runKey.style.fill = "rgba(255,255,255,0.15)"
        }

        
    })



    tick()
}